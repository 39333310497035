import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from "vue-meta-info"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import { POST, GET } from './api/http'
import mavonEditor from 'mavon-editor'
import locale from 'element-ui/lib/locale/lang/en'
import i18n from '@/i18n'
import moment from 'moment'
import VScrollbar from 'vue-scrollable-container3';
import 'vue-scrollable-container3/styles.scss';
import './dialogDrag'
import 'mavon-editor/dist/css/index.css'
// import './assets/css/main.css'
import './assets/css/index.scss'
Vue.config.productionTip = false
Vue.prototype.$post = POST;
Vue.prototype.$get = GET;
Vue.prototype.moment = moment;
Vue.prototype.cancels = [];

Vue.use(MetaInfo).use(VScrollbar);
Vue.use(ElementUI, {
	 i18n: (key, value) => i18n.t(key, value)
});
Vue.use(mavonEditor);

window.$vm = new Vue({
  router,
  store,
	i18n,
  render: h => h(App),
	// mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
	// mounted() {
	// 	document.dispatchEvent(new Event('render-event'))
	// },
}).$mount('#app')
