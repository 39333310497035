const state = {
  clientWidth: 900,
	trigger: true,
	isFullScreen: false,
	lang: localStorage.getItem('lang') || 'zh'
}

const mutations = {
 SET_CLIENTWIDTH: (state, data) => {
   state.clientWidth = data
 },
 SET_LANG: (state, data) => {
	 state.lang = data
 },
 SET_TRIGGER: (state, data) => {
	 state.trigger = data
 },
	SET_ISFULLSCREEN: (state, data) => {
	 state.isFullScreen = data
 }
}

const actions = {
	
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
