<template>
	<div class="flex jcc aic h100 loading">
		<img class="logo" src="@/assets/img/logo.png" /> <span>正在登录，请稍后....</span>
	</div>
</template>

<script>
	export default {
		methods: {
			getPath() {
				if(this.$route.query.code) {
					debugger
					this.$store.dispatch('user/getWxUser', {
						code: this.$route.query.code,
						state: this.$route.query.state
					}).then(res => {
						// console.log(res);
						if(res.data.Data.Phone && res.data.Data.ClientPwd) {
							this.$store.dispatch('user/login', {
								name: res.data.Data.UserName,
								loginType: 3,
								password: res.data.Data.UnionId
							}).then(res => {
								this.$router.push('/admin');
								this.loading = false;
							}).catch(e => {
								this.loading = false;
							})
						} else {
							// this.$message.error("获取失败")
							this.$router.push("/bindPhone?UnionId=" + res.data.Data.UnionId);
						}
					})
				}
			}
		},
		mounted() {
			this.getPath();
		}
	}
</script>

<style scoped lang="scss">
	.loading {
		background-color: #fff;
		color: #333;
		font-size: 16px;
	}
	
	.logo {
		width: 22px;
	}
</style>