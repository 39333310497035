<template>
	<div class="oLeft">
		<div class="oContent">
			<!-- <h4>{{$t('main.langmodule')}}</h4> -->
			<h4 class="flex flex-ac"><span>{{$t('main.langmodule')}}</span>
				<el-tooltip class="item" effect="dark" :content="$t('main.tipsLangModel')" placement="top">
					<img src="../../assets/img/question.png" style="width: 15px; margin-left: 3px;" />
				</el-tooltip>
			</h4>
			<div class="o-slider mt10">
				<!-- <div class="flex jcsb">
					<span>{{$t('main.ignore')}}</span>
					<span>{{$t('main.refer')}}</span>
				</div> -->
				<el-select size="small" @change="handleChangeModelLang" v-model="settings.Model_Language"
					style="width: 100%;">
					<el-option value="LLM">LLM</el-option>
					<el-option value="文言一心">文言一心</el-option>
					<el-option value="通义千问">通义千问</el-option>
				</el-select>
			</div>
			<div class="mt20" v-if="ezshow">
				<h4 class="flex flex-ac"><span>微调模型</span></h4>
				<div class="o-slider mt10">
					<el-select size="small" filterable clearable @change="handleChangeFineTuningModel"
						v-model="settings.FineTuningModel" style="width: 100%;">
						<el-option v-for="it in finetunes" :label="it" :value="it" :key="it"></el-option>
					</el-select>
				</div>
			</div>
			<h4 class="mt20">{{$t('main.outputPreset')}}</h4>
			<el-select @change="handlePreset"  clearable size="small" v-model="settings.presets_id" class="w100 mt20 lft-select" :placeholder="$t('main.outputPreset')">
				<el-option :value="it.presets_id" v-for="it in presets" :label="it.presets" :key="it.presets_id">
					<span class="flex w100 jcsb aic">
						<span>{{it.presets}}</span>
						<span class="el-icon-close pre-close" style="color: #c50;" @click.stop="onRemovePreset(it.presets_id)">
							
						</span>
					</span>
				</el-option>
			</el-select>
			<div class="select-res">
				<el-input rows="5" type="textarea" @change="handleChangeAnPre" resize="none"
					v-model="settings.AnswerPresupposition">
				</el-input>
			</div>
			<h4 class="mt20">{{$t('main.outputAccuracy')}}</h4>
			<!-- 回答精度 -->
			<div class="o-slider mt10" v-if="!isCheckedRadio">
				<div class="flex jcsb">
					<span>{{$t('main.accurate')}}</span>
					<span>{{$t('main.creative')}}</span>
				</div>
				<el-slider class="mt10" :show-tooltip="showtips" @change="handleChangeAnAc" :step="0.1" :min="0"
					:max="2" v-model="settings.AnswerAccuracy"></el-slider>
				<div class="flex jcsb">
					<span>0</span>
					<span>2</span>
				</div>
			</div>
			<div class="o-slider mt10" v-else>
				<div class="flex jcsb">
					<span>{{$t('main.accurate')}}</span>
					<span>{{$t('main.creative')}}</span>
				</div>
				<el-slider class="mt10" :min="0" :max="2" :value="0"></el-slider>
				<div class="flex jcsb">
					<span>0</span>
					<span>2</span>
				</div>
			</div>
			<h4 class="mt30">{{$t('main.tokenLen')}}</h4>
			<!--回答长度-->
			<div class="o-slider mt10" v-if="!isCheckedRadio">
				<el-slider :show-tooltip="showtips" @change="handleChangeLength" :min="0" :max="4000"
					v-model="settings.AnswerLength"></el-slider>
				<div class="flex jcsb">
					<span>0</span>
					<span>4000</span>
				</div>
			</div>
			<div class="o-slider mt10" v-else>
				<el-slider :min="0" :max="4000" :value="4000"></el-slider>
				<div class="flex jcsb">
					<span>0</span>
					<span>4000</span>
				</div>
			</div>
			<h4 class="mt30">{{$t('main.contextualContext')}}</h4>
			<!--前后语境-->
			<div class="o-slider mt10" v-if="!isCheckedRadio">
				<div class="flex jcsb">
					<span>{{$t('main.ignore')}}</span>
					<span>{{$t('main.refer')}}</span>
				</div>
				<el-slider :show-tooltip="showtips" @change="handleChangeLanguageER" :min="-1" :max="1"
					v-model="settings.LanguageERnvironment"></el-slider>
			</div>
			<div class="o-slider mt10" v-else>
				<div class="flex jcsb">
					<span>{{$t('main.ignore')}}</span>
					<span>{{$t('main.refer')}}</span>
				</div>
				<el-slider :min="-1" :max="1" :value="0"></el-slider>
			</div>
			<!-- <div class="mt30">
				<el-button size="small" type="primary">运价插件</el-button>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				value1: '',
				// eslint-disable-next-line
				showtips: (this.clientWidth < 800 ? false : true)
			}
		},
		computed: {
			...mapState('topic', ['settings', 'finetunes', 'topicList', 'isCheckedRadio', 'presets']),
			...mapState('user', ['userInfo', 'ezshow']),
			...mapState('system', ['clientWidth']),
		},
		mounted() {
		},
		methods: {
			handleChangeModelLang() {
				this.onUpdateSetting();
			},
			handleChangeFineTuningModel(val) {
				this.onUpdateSetting();
			},
			handleChangeAnPre(val) {
				this.onUpdateSetting();
			},
			handleChangeAnAc(val) {
				this.onUpdateSetting();
			},
			handleChangeLength(val) {
				this.onUpdateSetting();
			},
			handleChangeLanguageER(val) {
				this.onUpdateSetting();
			},
			handlePreset(val) {
				const settings = this.settings;
				if(val) {
					const fdObj = this.presets.find(ds => ds.presets_id == val);
					settings.AnswerPresupposition = fdObj.presets;
				} else {
					settings.presets_id = '';
					settings.AnswerPresupposition = '';
					this.$store.commit('topic/SET_SETTINGS', settings);
				}
				
				this.onUpdateSetting();
			},
			onRemovePreset(id) {
				// console.log(id)
				const settings = this.settings;
				if(settings.presets_id == id) {
					settings.AnswerPresupposition = '';
					settings.presets_id = '';
				}
				this.$store.commit('topic/SET_SETTINGS', settings);
				this.$store.dispatch("topic/delTopicPreset", { id }).then(res => {
					this.$message.success("删除成功");
					// this.onUpdateSetting();
				});
			},
			insertLog() {
				if (this.settings.TopicSettingId) {
					this.$store.dispatch('topic/getTopicData', {
						topicId: this.settings.TopicId
					});
				} else {
					const settings = this.settings;
					settings.UserId = this.userInfo.UserId;
					const params = {
						"topicTitle": '新的会话',
						"isDel": false,
						"userId": this.userInfo.UserId,
						"details": [{
							"role": "log",
							"topicContent": 'log',
						}],
						"setting": this.settings
					}
					// this.$store.dispatch('topic/addTopic', params);
					this.$store.dispatch('topic/addTopic', params).then(res => {
						this.$emit('onBackAdd', res.data.Data);
					}).catch(e => {});
				}
			},
			onUpdateSetting() {
				if (!this.settings.TopicSettingId) {
					this.insertLog();
					return;
				}
				this.$store.dispatch('topic/updateSetting', this.settings).then(res => {
					// this.$message.success(res.data.Message)
					if (res.data.Success) {
						this.insertLog();
						this.$message.success(this.$t('main.saveOk'))
					}
				})
			}
		}
	}
</script>
<style>
	.el-tooltip__popper {
		max-width: 200px;
	}
	
	
	.pre-close {
		display: none;
	}
	
	.hover .pre-close {
		display: inline-block;
	}
</style>
<style lang="scss" scoped>
	@import '../../assets/css/admin.scss';
</style>
