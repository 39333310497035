import { POST, GET } from '@/api/http';
const state = {
  token: '',
	userInfo: '',
	ezshow: false,
	paymentState: ''
}

const mutations = {
 SET_USER: (state, userInfo) => {
   state.userInfo = userInfo
 },
 SET_TOKEN: (state, token) => {
   state.token = token
 },
 SET_EZSHOW: (state, data) => {
   state.ezshow = data
 },
 SET_PAYMENTSTATE: (state, data) => {
   state.paymentState = data
 },
}

const actions = {
  login({ commit, dispatch }, params) {
		return new Promise((resolve, reject) => {
			POST("/User/GetToken", params).then((res) => {
				if(res.data.Success) {
					commit('SET_TOKEN', res.data.Token);
					localStorage.setItem('token', res.data.Token);
					resolve(res);
				} else {
					reject(res);
				}
			}).catch(e => {
				reject(e)
			})
		})
	},
	sendCode({ commit, dispatch }, params) {
		return new Promise((resolve, reject) => {
			GET("/User/SendPhoneCode?type=" + params.type + "&phone=" + params.phone + "&phoneAreaCode=" + params.phoneAreaCode + "&UnionId=" + params.UnionId).then((res) => {
				if(res.data.Success) {
					resolve(res);
				} else {
					reject(res);
				}
			}).catch(e => {
				reject(e)
			})
		})
	},
	getEz({ commit }, payload) {
		return new Promise((resolve, reject) => {
			GET("/Topic/GetEzcarry?name=" + payload.name).then((res) => {
				if(res.status == 200) {
					commit('SET_EZSHOW', res.data.Data);
					resolve(res);
				} else {
					reject(res);
				}
			})
		})
	},
	getUserInfo({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			GET("/User/GetCurrentUser").then((res) => {
				if(res.data.Success) {
					commit('SET_TOKEN', localStorage.getItem('token'));
					commit('SET_USER', res.data.Data);
					dispatch('getEz', { name: res.data.Data.Phone });
					dispatch('topic/getDefaultSettings', { setting_dype: 'LLM' }, { root: true });
					dispatch('topic/getTopicPreset', { UserId: res.data.Data.UserId }, { root: true });
					// dispatch('topic/getTopicList', { title: '' }, { root: true });
					resolve(res);
				} else {
					reject(res);
				}
			})
		})
	},
	registerUser({ commit, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			POST("/User/AddUser", payload).then((res) => {
				if(res.data.Success) {
					resolve(res);
				} else {
					reject(res);
				}
			})
		})
	},
	getWxUser({ commit, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			GET("/User/OpenThirdPartCallBack?code=" + payload.code + "&state=" + payload.state).then((res) => {
				if(res.data.Success) {
					resolve(res);
				} else {
					reject(res);
				}
			})
		})
	},
	aliPay({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			GET('/User/AlipayCashNew?userId=' + payload.uid + '&payType=' + payload.payType + '&memberType=' + payload.memberType).then((res) => {
				if(res.data.Success) {
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	aliPayCallback({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			GET('/User/AlipayNotity' + payload.queryParams).then((res) => {
				if(res.data.Success) {
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	wxPayQueryOrder({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			GET('/User/WxQueryOrder?arpPaymentId=' + payload.arpPaymentId).then((res) => {
				if(res.data.Success) {
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
