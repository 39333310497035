<template>
  <div id="app" class="page-container">
		<keep-alive :include="['index', 'news', 'products', 'case']">
			<router-view></router-view>
		</keep-alive>
  </div>
</template>
<script>
	import { mapState } from "vuex"
	import { GET } from '@/api/http'
	export default {
		watch: {
			paymentState(val) {
				console.log(val)
			}
		},
		computed: {
			...mapState('user', ['paymentState'])
		},
		mounted() {
			const that = this;
			let clientWidth = document.body.clientWidth;
			that.$store.commit('system/SET_CLIENTWIDTH', clientWidth);
			// that.$store.commit('system/SET_LANG', localStorage.getItem('lang') || 'zh');
			window.onresize = () => {
				clientWidth = document.body.clientWidth;
				that.$store.commit('system/SET_CLIENTWIDTH', clientWidth);
			}
			
			// GET("/User/WxQueryOrder?arpPaymentId=6DF240C2-90AF-44EB-B0C2-F0BD6091F5F9")
		}
	}
</script>

<style lang="scss" scoped>
	@import url("./assets/css/index.scss");
</style>
