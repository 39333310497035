<template>
	<div>
		<header class="flex jcsb aic">
			<h3><img src="../../assets/img/logo@2x.png" style="height: 1.5rem; float: left;" /></h3>
			<div class="flex aic">
				<!-- <span class="el-icon-full-screen" @click="onFullScreen" style="cursor: pointer;"></span> -->
				<span class="el-icon-time ml20" style="cursor: pointer;" v-if="$store.state.system.clientWidth< 700"
					@click="handleShowDrawer(2)" type="primary"></span>
				<span class="el-icon-setting ml20" style="cursor: pointer;" v-if="$store.state.system.clientWidth< 900"
					@click="handleShowDrawer(1)" type="primary">
				</span>
				<span class="ml20 lang" :class="active=='zh'&&'active'" @click="onChangeLang('zh')">中</span>
				<el-divider direction="vertical"></el-divider>
				<span class="lang" :class="active=='en'&&'active'"  @click="onChangeLang('en')">EN</span>
				<span class="ml10"></span>
				<el-dropdown>
					<span class="el-dropdown-link">
						<el-avatar shape="square" v-if="$store.state.user.userInfo.UserProfileAzurePicture" :size="32" class="ml20" :src="$store.state.user.userInfo.UserProfileAzurePicture">
						</el-avatar>
						<el-avatar shape="square" v-else :size="32" class="ml20" :src="require('@/assets/img/avt.png')">
						</el-avatar>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<div class="flex-c mt10">
								<div class="flex aic jcfs">
									<!-- <el-avatar shape="square" :size="32" :src="require('@/assets/img/avt.png')">
									</el-avatar> -->
									<el-avatar shape="square" v-if="$store.state.user.userInfo.UserProfileAzurePicture" :size="32" class="ml20" :src="$store.state.user.userInfo.UserProfileAzurePicture">
									</el-avatar>
									<el-avatar shape="square" v-else :size="32" class="ml20" :src="require('@/assets/img/avt.png')">
									</el-avatar>
									<div class="flex-c ml10">
										<h4>{{userInfo.UserName}}</h4>
										<span class="btn-logout" @click="handleOut">{{$t('main.logoutText')}}</span>
									</div>
								</div>
								<div class="exp flex aic jcsb mt20">
									<div class="flex-c">
										<h5>{{$t('main.expDate')}}</h5>
										<span>2023-05-11{{$store.state.system.lang=='zh'?'到期':''}}</span>
									</div>
									<div class="btn-pay" @click="toPay">
										{{$t('main.recharging')}}
									</div>
								</div>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</header>
		<el-dialog v-dialogDrag class="memberDialog" :title="$t('main.activateMember')" :visible.sync="dialogVisible" width="80%" :close-on-click-modal="false"
			:before-close="handleClose">
			<div>
				<div>{{$t('main.memberType')}}</div>
				<el-row :gutter="20" class="mt20">
					<el-col :lg="6" :sm="8" :xs="12">
						<div class="box-card flex-c aic jcc" :class="memberType=='1个月' && 'active'" @click="memberType='1个月'">
							<h3>{{$t('main.oneMonth')}}</h3>
							<h1>¥25</h1>
						</div>
					</el-col>
					<el-col :lg="6" :sm="8" :xs="12">
						<div class="box-card flex-c aic jcc" :class="memberType=='3个月' && 'active'" @click="memberType='3个月'">
							<h3>{{$t('main.season')}}</h3>
							<h1>¥88</h1>
						</div>
					</el-col>
					<el-col :lg="6" :sm="8" :xs="12">
						<div class="box-card flex-c aic jcc" :class="memberType=='半年' && 'active'" @click="memberType='半年'">
							<h3>{{$t('main.halfAYear')}}</h3>
							<h1>¥240</h1>
						</div>
					</el-col>
					<el-col :lg="6" :sm="8" :xs="12">
						<div class="box-card flex-c aic jcc" :class="memberType=='1年' && 'active'" @click="memberType='1年'">
							<h3>{{$t('main.oneYear')}}</h3>
							<h1>¥460</h1>
						</div>
					</el-col>
				</el-row>
				<div class="mt30">{{$t('main.paySelect')}}</div>
				<div class="mt20 payment flex aic">
					<div class="payitem" :class="payType=='支付宝'&&'active'" @click="payType='支付宝'"><img src="@/assets/img/zhifubao.png" /> <span>{{$t('main.alipay')}}</span></div>
					<div class="payitem" :class="payType=='微信'&&'active'"  @click="payType='微信'"><img src="@/assets/img/wx.png" /><span>{{$t('main.wechatpay')}}</span></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<div class="w100 flex aic jcc">
					<div class="btn-submit-pay" @click="doPay">{{$t('main.dopay')}}</div>
				</div>
				<div class="w100 flex aic jcc mt10">
					<el-checkbox v-model="isAgree"></el-checkbox> <span style="margin-left: 0.375rem;">{{$t('main.agree')}}</span>
					<router-link to="/serice">{{$t('main.payAgreemt')}}</router-link>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { getCurrentInstance } from 'vue';
	import { mapState } from 'vuex'
	
	export default {
		data() {
			return {
				dialogVisible: false,
				active: 'zh',
				proxy: null,
				aliForm: '',
				memberType: '1个月',
				payType: '支付宝',
				isAgree: false
			}
		},
		computed: {
			...mapState('topic', ['list', 'settings']),
			...mapState('user', ['userInfo']),
			...mapState('system', ['isFullScreen']),
		},
		methods: {
			handleClose() {
				this.dialogVisible = false;
			},
			handleOut() {
				localStorage.removeItem('token');
				this.$router.push('/login')
			},
			onChangeLang(val) {
				localStorage.setItem('lang', val);
				this.active = val;
				this.$store.commit('system/SET_LANG', val);
				this.proxy.$i18n.locale = val;
				// localStorage.setItem('')
				// console.log(this.$store.state.system.lang);
				// location.reload();
			},
			toPay() {
				this.dialogVisible = true;
			},
			doPay() {
				if(!this.isAgree) {
					this.$message.warning('请同意协议');
					return;
				}
				window.name = 'payment';
				window.open("/payment?uid=" + this.$store.state.user.userInfo.UserId + '&memberType=' + this.memberType + '&payType=' + this.payType, "_blank");
			},
			handleShowDrawer(val) {
				this.$emit('onShowDrawer', val)
			},
			onFullScreen() {
				if(this.isFullScreen) {
					if(document.exitFullScreen) {
						document.exitFullscreen()
					}
					// 兼容火狐
					if(document.mozCancelFullScreen) {
						document.mozCancelFullScreen()
					}
					// 兼容谷歌等
					if(document.webkitExitFullscreen) {
						document.webkitExitFullscreen()
					}
					// 兼容IE
					if(document.msExitFullscreen) {
						document.msExitFullscreen()
					}
					
					this.$store.commit('system/SET_ISFULLSCREEN', false);
				} else {
					if (document.documentElement.RequestFullScreen) {
						document.documentElement.RequestFullScreen();
					}
					// 兼容火狐
					// console.log(document.documentElement.mozRequestFullScreen)
					if(document.documentElement.mozRequestFullScreen) {
						document.documentElement.mozRequestFullScreen();
					}
					// 兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
					if(document.documentElement.webkitRequestFullScreen) {
						document.documentElement.webkitRequestFullScreen();
					}
					// 兼容IE,只能写msRequestFullscreen
					if(document.documentElement.msRequestFullscreen) {
						document.documentElement.msRequestFullscreen();
					}
					
					this.$store.commit('system/SET_ISFULLSCREEN', true);
				}
			}
		},
		mounted() {
			let { proxy } = getCurrentInstance();
			this.proxy = proxy;
			this.active = localStorage.getItem('lang') || 'zh';
			window.addEventListener("fullscreenchange", (e) => {
				console.log(e);
				// if(e.isFullscreen){
				// 	// this.isFullFlag = true
				// }else{
				// 	// this.isFullFlag = false
				// }
			})
		}
	}
</script>
<style>
	.memberDialog .el-dialog {
		max-width: 50rem;
	}
</style>
<style lang="scss" scoped>
	@import '../../assets/css/admin.scss';
	.lang {
		cursor: pointer;
		color: #888;
		&.active {
			color: #333;
		}
	}
	
	header {
		h3 {
			font-family: HelveticaNeue-Bold, HelveticaNeue;
			font-weight: bold;
		}
	}
	
	.box-card {
		margin-bottom: 1.225rem;
		cursor: pointer;
	}
</style>
