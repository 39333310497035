import axios from 'axios';
import Vue from 'vue';
import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import router from '@/router';
// import qs from 'qs';

/**
 * axios 配置
 */
axios.defaults.timeout = 120000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.baseURL = 'http://222.181.10.230:8030/';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['allSource'] = 'PC';
// axios.defaults.headers.post['timestamp'] = new Date().getTime();
// Axios实现请求重试
axios.defaults.retry = 1; // 重试次数
axios.defaults.retryDelay = 1000; // 重试延时
axios.defaults.shouldRetry = () => true; // 重试条件，默认只要是错误都需要重试

axios.defaults.requestCount = 0;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})
const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
// 请求拦截器
axios.interceptors.request.use(config => {
	config.cancelToken = new CancelToken((c) => {
		// eslint-disable-next-line
		Vue.prototype.cancels.push(c);
	});
	const noProgressUrl = ['/Topic/Addcomment'];
	// console.log(noProgressUrl.includes(config.url))
	if(!noProgressUrl.includes(config.url)) {
		NProgress.start();
	}
	return config;
}, err => {
	NProgress.done();
	return Promise.resolve(err);
})

// 响应拦截器
axios.interceptors.response.use(response => {
	NProgress.done();
	if(!response.data.Success && response.data.Message) {
		Message.error(response.data.Message);
		Vue.prototype.cancels.forEach((cancel) => {
			cancel();
		});
	}
	return response;
}, err => {
	NProgress.done();
	Vue.prototype.cancels.forEach((cancel) => {
		cancel();
	});
	const cancelCodes = ['ERR_CANCELED'];
	if(err.message == 'Network Error') {
		// Message.error(window.$vm.$t('login.loginExpired'));
		localStorage.clear();
		window.$vm.$router.push('/login');
	} else if(cancelCodes.includes(err.code)) {
		throw new Error(err)
	} else {
		Message.error(err.message);
	}
	return Promise.resolve(err);
})


/* 封装post方法 */
export function POST(url, data = {}) {
	return new Promise((resolve, reject) => {
		// const params = qs.stringify(data);
		let params = data;
		let headers = {
			"Content-Type": "application/json;charset=utf-8;",
		}
		let token = store.state.user.token || localStorage.getItem('token');
		if(token) {
			headers.Authorization = "Bearer " + token;
		}
		axios.post('/webapi' + url, params, {
			headers
		}).then(response => {
			if (response.data) {
				resolve(response);
			} else if(response.response) {
				Message.error(response.response.data.title);
				reject();
			} else {
				Message.error(response.message);
				reject();
			}
		}).catch(err => {
			reject(err);
		})
	})
}

export function GET(url) {
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
	}
	let token = store.state.user.token || localStorage.getItem('token');
	if(token) {
		headers.Authorization = "Bearer " + token;
	}
	return new Promise((resolve, reject) => {
		axios.get('/webapi' + url, {
			headers,
		}).then(response => {
			if (response.data) {
				resolve(response);
			} else if(response.response && response.response.data && response.response.data.title) {
				Message.error(response.response.data.title);
				reject();
			} else {
				// Message.error(response.message);
				reject();
			}
		}).catch(err => {
			reject(err);
		})
	})
}

// router.beforeEach((to, from, next) => {//当路由切换页面的时候，遍历全局数组，将上一个页面的所有请求cancel掉
//   Vue.prototype.__cancels__.forEach((cancel) => {
//     cancel();
//   });
//   Vue.prototype.__cancels__ = [];
// })