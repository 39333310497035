<template>
	<div class="login-container">
		<!-- Login -->
		<div class="logo flex aic">
			<h2><img src="../assets/img/logo-white@2x.png" style="height: 1.75rem;" /></h2>
		</div>
		<div class="lc-box">
			<el-row :gutter="20">
				<el-col :span="12" v-if="$store.state.system.clientWidth>=900 && $store.state.system.lang=='zh'">
					<div class="grid-content swiper-ca bg-purple h100 flex jcc">
						<el-carousel height="460px" arrow="nerver">
							<el-carousel-item>
								<img src="../assets/img/swiper01.png" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="../assets/img/swiper02.png" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="../assets/img/swiper03.png" />
							</el-carousel-item>
						</el-carousel>
						<!-- <div>
							<div class="swiper-title">
								<h2>E-CHAT</h2>
								<div>让跨境贸易变得更智慧</div>
							</div>
							<div class="swiper-desc">
								E-CHAT是一款基于多语言智能模型驱动的AI产品，能够快速简单地解决用户跨境需求。
							</div>
							<div class="op-ts">
								<div>
									
								</div>
								<div>
									
								</div>
							</div>
						</div> -->
						<!-- <div class="lc-foo mt20">
							<i class="el-icon-circle-check"></i><span>免费试用</span>
							<i class="el-icon-circle-check"></i><span>NLP引导式互动交易</span>
							<i class="el-icon-circle-check"></i><span>打造Plugin 配合各AI模型</span>
						</div> -->
					</div>
				</el-col>
				<el-col :span="$store.state.system.clientWidth>=900&&$store.state.system.lang=='zh'?12:24"
					class="flex-c jcc">
					<div class="grid-content bg-purple lc-form jcfs">
						<div>
							<h2>{{$t("login.loginBoxTitle")}}</h2>
							<h6>{{$t('login.noCount')}}？<router-link to="/wxLogin?type=1">{{$t('login.registerAccount')}}
									<span class="arr el-icon-arrow-right"></span> </router-link>
							</h6>
						</div>
						<router-link to="/wxlogin">
							<div class="btn-wx-login w100 flex-jc-ac">
								<img src="../assets/img/wx.png" /> <a>{{$t('login.wechatLogon')}}</a>
							</div>
						</router-link>
						<el-divider style="color: #ccc; border-color: #333;">{{$t('login.others')}}</el-divider>
						<div class="log-tags flex aic">
							<div class="log-tag-item" @click="changeType(0)" :class="active==0&&'active'">
								{{$t('login.mobile')}}</div>
							<div class="log-tag-item" @click="changeType(1)" :class="active==1&&'active'">
								{{$t('login.password')}}</div>
						</div>
						<div class="form-bx" v-loading="loading" v-if="active===0">
							<div class="log-input flex aic mt10">
								<span class="el-icon-mobile-phone"></span>
								<el-input :placeholder="$t('login.enterPhoneNumber')" v-model="phoneform.phone"
									auto-complete="off" maxlength="11" type="text" clearable>
								</el-input>
							</div>
							<div class="flex flex-jc-ac log-input mt10">
								<span class="el-icon-tickets"></span>
								<el-input :placeholder="$t('login.inputCode')" type="text" auto-complete="off"
									v-model="phoneform.phoneCode" clearable>
								</el-input>
								<!-- <a @click="onSendCode">{{$t('login.obtainCode')}}</a> -->
								<a v-if="isSendedOk"><span style="color: red;">{{count}}s</span></a><a @click="onSendCode" v-else>获取验证码</a>
							</div>

							<el-button class="btn-login w100 mt30" @click="handleSubmit">{{$t('login.loginBoxTitle')}}
							</el-button>
							<div class="mt10 flex aic">
								<el-checkbox /> {{$t('login.readAndAgree')}}<a>{{$t('login.serviceAgreement')}}</a>
							</div>
						</div>
						<div class="form-bx" v-loading="loading" v-else>
							<div class="log-input flex aic mt10">
								<span class="el-icon-user"></span>
								<el-input :placeholder="$t('login.userName')" type="text" v-model="loginform.usename">
								</el-input>
							</div>
							<div class="flex flex-jc-ac log-input mt10">
								<span class="el-icon-lock"></span>
								<el-input type="password" @keyup.enter.native="handleSubmit"
									v-model="loginform.password" :placeholder="$t('login.userPass')"></el-input>
							</div>
							<!-- <div class="flex jcsb aic mt10"><el-checkbox>记住账号</el-checkbox><router-link to="/findpass">忘记密码?</router-link></div> -->
							<el-button class="btn-login w100 mt30" @click="handleSubmit">{{$t('login.loginBoxTitle')}}
							</el-button>
							<div class="mt10 flex aic" style="cursor: pointer;" @click="isagree=!isagree">
								<el-checkbox v-model="isagree" @change="isagree=!isagree"/> {{$t('login.readAndAgree')}}
								<a>{{$t('login.serviceAgreement')}}</a>
							</div>
						</div>
					</div>

				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 1,
				loading: false,
				loginform: {
					usename: '',
					password: ''
				},
				phoneform: {
					phone: '',
					phoneCode: ''
				},
				isSendedOk: false,
				count: 60,
				timer: null,
				isagree: true
			}
		},
		methods: {
			changeType(val) {
				this.active = val;
				this.phoneform = {
					phone: '',
					phoneCode: ''
				}
			},
			onSendCode() {
				if (!this.phoneform.phone.trim()) {
					this.$message.warning('请输入手机号');
					return;
				}
				const regx = /^1[3456789]\d{9}$/;
				if (!regx.test(this.phoneform.phone)) {
					this.$message.warning("请填写正确的手机号");
					return;
				}
				this.loading = true;
				this.$store.dispatch('user/sendCode', {
					type: 1,
					phone: this.phoneform.phone,
					phoneAreaCode: '86'
				}).then(res => {
					// this.$router.push('/admin');
					this.loading = false;
					if(res.data.Success) {
						this.isSendedOk = true;
						this.timer = setInterval(() => {
							if(this.count == 0) {
								this.isSendedOk = false;
								this.count = 60;
								clearInterval(this.timer);
							} else {
								this.count -= 1;
							}
						}, 1000)
					}
				}).catch(e => {
					this.loading = false;
				})
			},
			handleSubmit() {
				if (this.active == 1) {
					const {
						usename,
						password
					} = this.loginform;
					if (!usename || !password) {
						this.$message.warning(this.$t('login.requireUser'))
						return;
					}
					if (!this.isagree) {
						this.$message.warning('请同意协议')
						return;
					}
					this.loading = true;
					this.$store.dispatch('user/login', {
						loginType: 1,
						name: usename,
						password
					}).then(res => {
						this.$router.push('/admin');
						this.loading = false;
					}).catch(e => {
						this.loading = false;
					})
				} else {
					const {
						phone,
						phoneCode
					} = this.phoneform;
					if (!phone || !phoneCode) {
						this.$message.warning("请输入手机号和验证码")
						return;
					}
					const regx = /^1[3456789]\d{9}$/;
					if (!regx.test(phone)) {
						this.$message.warning("请填写正确的手机号");
						return;
					}
					this.loading = true;
					this.$store.dispatch('user/login', {
						loginType: 2,
						name: phone,
						password: phoneCode
					}).then(res => {
						this.$router.push('/admin');
						this.loading = false;
					}).catch(e => {
						this.loading = false;
					})
				}
			}
		},
		mounted() {
			if (localStorage.getItem('token')) {
				this.$router.push('/admin');
			}
		}
	}
</script>
<style>
	.el-carousel__item {
		text-align: right;
	}
	.el-carousel__indicators {
		position: fixed!important;
	}
	.el-carousel__button {
		width: 6px!important;
		height: 6px!important;
		border-radius: 50%;
	}
	.el-carousel__arrow {
		opacity: 0;
	}
</style>
<style lang="scss" scoped>
	@import '../assets/css/login.scss';
	
	.logo {
		font-size: 1.5rem;
		img {
			float: left;
			margin-right: 6px;
		}
	}
	.swiper-ca {
		img {
			height: 100%;
		}
	}
	
</style>
