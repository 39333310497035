<template>
	<div class="main">
		<o-header @onShowDrawer="handleShowDrawer"></o-header>
		<div class="container">
			<oLeftSetting v-if="$store.state.system.clientWidth>=900 && trigger" @onBackAdd="onBackAdd"></oLeftSetting>
			<div class="o-triger" :class="!trigger&&'rot180'" v-if="$store.state.system.clientWidth>=900" @click="changeTrigger">
				<img src="../../assets/img/ar2.png" />
			</div>
			<div class="oCenter">
				<keep-alive>
					<router-view :changing="changing" :selectTopicId="selectTopicId" @onBackAdd="onBackAdd">
					</router-view>
				</keep-alive>
			</div>
			<oRight v-if="$store.state.system.clientWidth>=700" @onBackAdd="onBackAdd" :isAdd="isAdd" @onBack="onBackSelectTopic"></oRight>
		</div>
		<el-drawer :title="$t('main.setting')" :size="300" :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
			<div style="padding: 1.25rem;">
				<oLeftSetting @onBackAdd="onBackAdd"></oLeftSetting>
			</div>
		</el-drawer>
		<el-drawer :title="$t('main.historyTopic')" :size="300" :visible.sync="drawer2" :direction="direction" :before-close="handleCloseDrawer2">
			<div class="container"><oRight style="width: 100%;" :isAdd="isAdd" @onBackAdd="onBackAdd" @onBack="onBackSelectTopic"></oRight></div>
		</el-drawer>
	</div>
</template>
<script>
	import oLeftSetting from './oLeft.vue';
	import oHeader from './header.vue';
	import oRight from './oRight.vue';
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				dialogVisible: false,
				topicIng: false,
				value1: '',
				value2: 0,
				value3: 3000,
				value4: 0,
				isHover: false,
				keyword: '',
				// selectTopicId: '',
				changing: false,
				screenWidth: 0,
				drawer: false,
				drawer2: false,
				direction: 'rtl',
				isAdd: false
			}
		},
		components: {
			oLeftSetting,
			oHeader,
			oRight
		},
		computed: {
			...mapState('topic', ['list', 'settings', 'selectTopicId', 'pageDefault']),
			...mapState('user', ['userInfo']),
			...mapState('system', ['trigger']),
		},
		methods: {
			handleCloseDrawer() {
				this.drawer = false;
			},
			handleShowDrawer(val) {
				if(val == 2) {
					this.drawer2 = true;
				}
				if(val == 1) {
					this.drawer = true;
				}
			},
			handleCloseDrawer2() {
				this.drawer2 = false;
			},
			getUser() {
				if (localStorage.getItem('token')) {
					this.topicIng = true;
					this.$store.dispatch('user/getUserInfo').then(() => {
						this.topicIng = false;
						this.$store.dispatch('topic/getFineTunes');
					}).catch(e => {
						this.topicIng = false;
					});
				} else {
					this.$router.push("/login")
				}
			},
			onBackSelectTopic(res) {
				this.handleSelectTopic(res, false);
			},
			onBackAdd(res) {
				this.handleSelectTopic(res, true);
			},
			handleSelectTopic(it, flag) {
				this.isAdd = false;
				if (it.TopicId != this.selectTopicId) {
					// this.selectTopicId = it.TopicId;
					this.changing = true;
					this.$store.commit('topic/SET_SELECTTOPIC_ID', it.TopicId);
					this.$store.commit('topic/SET_PAGEPARAMS', {
						pageSize: 30,
						pageIndex: 1
					});
					
					this.$nextTick(() => {
						this.$store.dispatch('topic/getTopicData', {
							topicId: it.TopicId
						}).then(() => {
							this.changing = false;
							this.drawer2 = false;
							// console.log(this.$refs)
							this.$store.commit('topic/SET_BACK', true);
							if(flag) {
								this.isAdd = true;
							}
						}).catch(() => {
							this.changing = false;
						});
					})
					// this.$abort();
				}
			},
			changeTrigger() {
				this.$store.commit('system/SET_TRIGGER', !this.trigger)
			}
		},
		mounted() {
			this.getUser();
		}
	}
</script>
<style>
	.answer .v-note-wrapper {
		min-height: auto;
		border: 0px;
		color: #000462;
		line-height: 1.35;
		min-width: 6.25rem;
	}

	.answer .v-note-wrapper .v-note-panel .v-note-show .v-show-content,
	.v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
		padding: 0px;
	}
	
	.markdown-body {
		color: #000462;
	}
</style>
<style lang="scss" scoped>
	@import '../../assets/css/admin.scss';
</style>
