import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import HomeView from '../views/Index.vue'
import Login from '../views/Login.vue'
import wxLogin from '../views/wxLogin.vue'
import wxLoading from '../views/wxLoading.vue'
import mobileReg from '../views/mobileReg.vue'
import Layout from '@/components/Layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
		redirect: '/login',
		meta: {
			title: '首页'
		}
  },
	{
	  path: '/login',
	  name: 'login',
	  component: Login,
		meta: {
			title: '登录'
		}
	},
	{
	  path: '/wxlogin',
	  name: 'wxlogin',
	  component: wxLogin,
		meta: {
			title: '微信登录'
		}
	},
	{
	  path: '/wxloading',
	  name: 'wxloading',
	  component: wxLoading,
		meta: {
			title: '微信登录中'
		}
	},
	{
	  path: '/mobilereg',
	  name: 'mobilereg',
	  component: mobileReg,
		meta: {
			title: '手机注册'
		}
	},
	{
	  path: '/register',
	  name: 'register',
	  component: () => import('@/views/register.vue'),
		meta: {
			title: '用户注册'
		}
	},
	{
	  path: '/bindPhone',
	  name: 'bindPhone',
	  component: () => import('@/views/bindPhone.vue'),
		meta: {
			title: '绑定手机'
		}
	},
	{
	  path: '/alipay',
	  name: 'alipay',
	  component: () => import('@/views/alipay.vue'),
		meta: {
			title: '支付宝支付'
		}
	},
	{
	  path: '/payment',
	  name: 'payment',
	  component: () => import('@/views/payment.vue'),
		meta: {
			title: '支付'
		}
	},
	{
	  path: '/admin',
	  component: Layout,
	  redirect: '/admin/home',
	  children: [
	    {
	      path: '/admin/home',
	      component: () => import('@/views/admin/home/Index.vue'),
	      name: 'adminhome',
	      meta: { 
					title: 'Logistics Ai Engine',
					icon: 'dashboard',
					affix: true,
					requireAuth: true
				}
	    }
	  ]
	},
	{
	  path: '*',
	  component: () => import('@/404.vue'),
	}
]

const router = new VueRouter({
	mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
	if(to.meta.requireAuth && !localStorage.getItem('token')) {
		next({
			path: '/login'
		})
	} else {
		next();
	}
	document.title = to.meta.title || 'LOG AI ENGINE';
})
export default router
