import { POST, GET } from '@/api/http';
import { Message } from 'element-ui'
const state = {
  list: [],
	title: '',
	topicData: {},
	topicDataLoading: false,
	settings: {
		"AnswerPresupposition": "",
		"AnswerAccuracy": 0,
		"AnswerLength": 0,
		"LanguageERnvironment": 0,
		"Model_Language": "LLM",
		"IsFreight": false,
		"IsFineTuning": false,
		"IsPineCone": false,
		"IsFreightTest": false,
		"IsFineTuningTest": false,
		"IsPineConeTest": false,
		"IsTruck": false,
		"IsTruckTest": false,
		"IsFBA": false,
		"IsFBATest": false,
		"IsFBAorderManagement": false,
		"IsFBAorderManagementTest": false,
		"FineTuningModel": "",
		"TopicId": ""
	},
	selectTopicId: '',
	topicList: [],
	finetunes: [],
	scrollback: false,
	pageParams: {
		pageSize: 30,
		pageIndex: 1
	},
	isCheckedRadio: false,
	presets: []
}

const mutations = {
 SET_LIST: (state, data) => { state.list = data },
 SET_TITLE: (state, data) => { state.title = data },
 SET_TOPIC_DATA: (state, data) => { state.topicData = data },
 SET_TOPICDATA_LOADING: (state, data) => { state.topicDataLoading = data },
 SET_SETTINGS: (state, data) => {
	 if (data.IsFineTuning || data.IsFineTuningTest || data.IsPineCone || data.IsPineConeTest || data.IsFreightTest || data.IsFreight || data.IsTruckTest || data.IsTruck) {
	 	state.isCheckedRadio = true;
	 } else {
	 	state.isCheckedRadio = false;
	 }
	 state.settings = data
  },
 SET_TOPICLIST: (state, data) => { state.topicList = data },
 SET_SELECTTOPIC_ID: (state, data) => { state.selectTopicId = data },
 SET_FINETUNES: (state, data) => { state.finetunes = data },
 SET_BACK: (state, data) => { state.scrollback = data },
 SET_PAGEPARAMS: (state, data) => { state.pageParams = data },
 SET_PRESETS: (state, data) => { state.presets = data },
}

const actions = {
	getTopicList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			commit('SET_TITLE', payload.title);
			GET("/Topic/GetCurrentUserTopicList?title=" + payload.title).then((res) => {
				if(res.data.Success) {
					commit('SET_LIST', res.data.Data);
					resolve(res);
				} else {
					reject(res);
				}
			})
		})
	},
	updateTopicTitle({ commit, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			// commit('SET_USERID', 12334);
			POST("/Topic/EditTopicTitle", payload).then((res) => {
				if(res.data.Success) {
					dispatch('getTopicList', { title: '' });
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	deleteTopicTitle({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			// commit('SET_USERID', 12334);
			POST("/Topic/DeleteTopic/" + payload.topicId, {
				topicId: payload.topicId
			}).then((res) => {
				if(res.data.Success) {
					dispatch('getTopicList', { title: state.title })
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	getTopicPreset({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			POST("/Topic/GetTopicPresets", {
				UserId: payload.UserId
			}).then((res) => {
				console.log(res);
				if(res.data.Success) {
					commit("SET_PRESETS", res.data.Data);
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	/* eslint-disable */
	delTopicPreset({ commit, dispatch, state, rootState }, payload) {
		return new Promise((resolve, reject) => {
			POST("/Topic/DeleteTopicPresets", {
				presets_id: payload.id,
				UserId: rootState.user.userInfo.UserId
			}).then((res) => {
				if(res.data.Success) {
					dispatch("getTopicPreset", { UserId: rootState.user.userInfo.UserId });
					resolve(res.data);
				} else {
					reject(res);
				}
			})
		})
	},
	/* eslint-disable */
	getTopicData({ commit, state }, payload) {
		return new Promise((resolve, reject) => {
			if(payload.topicId) {
				GET("/Topic/GetTopic/" + payload.topicId + "?pageSize=" + state.pageParams.pageSize + "&pageIndex=" + state.pageParams.pageIndex).then((res) => {
					const settings = res.data.Data.Setting;
					if(res.data.Success) {
						commit("SET_TOPIC_DATA", res.data.Data);
						const list = Object.freeze(res.data.Data.Details);
						if(!settings.Model_Language) {
							settings.Model_Language = 'LLM';
						}
						commit("SET_SETTINGS", settings);
						if(state.pageParams.pageIndex == 1) {
							commit('SET_BACK', true);
							setTimeout(() => {
								commit('SET_BACK', false);
							}, 500);
							commit("SET_TOPICLIST", list);
						} else {
							commit("SET_TOPICLIST", [...list, ...state.topicList]);
						}
						// console.log(res.data.Data.Setting)
						resolve(res)
					} else {
						reject(res);
					}
					// commit("SET_TOPICDATA_LOADING", false);
				}).catch((e) => {
					// commit("SET_TOPICDATA_LOADING", false);
					reject(e)
				})
			} else {
				reject();
			}
		})
	},
	getTopicDataUnscroll({ commit }, payload) {
		return new Promise((resolve, reject) => {
			if(payload.topicId) {
				GET("/Topic/GetTopic/" + payload.topicId, {
					topicId: payload.topicId
				}).then((res) => {
					if(res.data.Success) {
						// commit("SET_TOPIC_DATA", res.data.Data);
						commit("SET_TOPICLIST", Object.freeze(res.data.Data.Details));
						commit('SET_BACK', false);
						resolve();
					} else {
						reject();
					}
				})
			}
		})
	},
	addTopic({ commit, dispatch, state, rootState }, payload) {
		return new Promise((resolve, reject) => {
			POST("/Topic/AddTopic", payload).then((res) => {
				if(res.data.Success) {
					const TopicId = res.data.Data.TopicId;
					let settings = res.data.Data.Setting;
					settings.TopicId = TopicId;
					commit("SET_SETTINGS", settings);
					// dispatch('getTopicList', { title: '' });
					dispatch('getTopicList', { title: state.title })
					dispatch("getTopicPreset", { UserId: rootState.user.userInfo.UserId });
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
	addComment({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			POST("/Topic/Addcomment", payload).then((res) => {
				if(res.data.Success) {
					dispatch('getTopicDataUnscroll', { topicId: payload.topicid });
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
	getDefaultSettings({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			GET("/Topic/GetSettingDefault?setting_dype=" + payload.setting_dype).then((res) => {
				if(res.data.Success) {
					const settings = state.settings;
					// console.log(res)
					settings.AnswerAccuracy = res.data.Data.AnswerAccuracy;
					settings.AnswerLength = res.data.Data.AnswerLength;
					settings.AnswerPresupposition = res.data.Data.AnswerPresupposition;
					settings.LanguageERnvironment = res.data.Data.LanguageERnvironment;
					commit("SET_SETTINGS", settings);
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
	updateSetting({ commit, dispatch, state, rootState }, payload) {
		return new Promise((resolve, reject) => {
			if(state.selectTopicId) {
				payload.TopicId = state.selectTopicId;
			}
			POST("/Topic/UpdateTopicSetting", payload).then((res) => {
				if(res.data.Success) {
					dispatch("getTopicPreset", { UserId: rootState.user.userInfo.UserId });
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
	addTopicDetail({ commit, dispatch, state }, payload) {
		return new Promise((resolve, reject) => {
			POST("/Topic/AddTopicDetail", payload).then((res) => {
				if(res.data.Success) {
					// console.log(res.data.Data);
					// commit("SET_TOPICLIST", [...state.topicList, res.data.Data]);
					dispatch('getTopicData', { topicId: payload.topicId });
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
	getFineTunes({ commit, dispatch, state }) {
		return new Promise((resolve, reject) => {
			GET("/Topic/GetFineTunes").then((res) => {
				if(res.data) {
					// console.log(res.data.Data);
					commit("SET_FINETUNES", res.data);
					resolve(res)
				} else {
					reject(res);
				}
			}).catch((e) => {
				reject(e)
			})
		})
	},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
