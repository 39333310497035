import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n);

const i18n = new VueI18n({
 locale: store.state.system.lang,
 legacy: false,
 globalInjection: true,
 messages: {
 	zh: {
		...require('./local-zh'),
		...zhLocale
	},
 	en: {
		...require('./local-en'),
		...enLocale
	}
 }
})

export default i18n