<template>
	<div class="oRight flex-c w100">
			<div class="oContent">
				<el-input size="small" v-model="keyword" clearable @change="handleSearchTopic"
					@keyup.enter.native="handleSearchTopic" class="w100" :placeholder="$t('main.search')" />
			</div>
			<div class="oHistory" ref="oHistory" element-loading-background="rgba(0, 0, 0, 0)" v-loading="topicIng">
				<!-- <div element-loading-background="rgba(0, 0, 0, 0)" v-loading="topicIng"></div> -->
				<div class="o-his-item ft-psr" :class="item.TopicId==selectTopicId&&'active'" v-for="(item, idx) in list"
					:key="idx" @click="handleSelectTopic(item)">
					<i class="el-icon-time"></i>
					<span class="his-title" :title="item.TopicTitle">
						<span @click.stop="()=>{ return false }">
							<el-input v-model="item.TopicTitle" @blur="handleBlur(item)"
								@change="handleChangeTopicTitle(item)" ref="topTitle" size="small"
								v-show="item.isEdit"></el-input>
						</span>
						<span v-show="!item.isEdit">{{item.TopicTitle}}</span>
					</span>
					<span class="el-icon-edit" @click.stop="handleEdit(item, idx)"></span>
					<span class="el-icon-delete" v-show="!(item.TopicId==selectTopicId)"
						@click.stop="handleDelete(item, idx)"></span>
				</div>
			</div>
			<div class="btn-add" @click="onAddTopic">{{$t('main.newTopic')}}</div>
	</div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		props: ['isAdd'],
		data() {
			return {
				topicIng: false,
				keyword: ''
			}
		},
		watch: {
			isAdd(val) {
				if(val) {
					this.scrollToBottom();
				}
			}
		},
		
		computed: {
			...mapState('topic', ['list', 'settings', 'selectTopicId', 'pageDefault']),
			...mapState('user', ['userInfo']),
		},
		methods: {
			handleEdit(it, i) {
				it.isEdit = true;
				this.$forceUpdate();
				this.$nextTick(() => {
					const refTopic = this.$refs.topTitle[i];
					refTopic.select();
				})
			},
			handleDelete(it) {
				this.$confirm(this.$t('main.confirmTitle'), this.$t('main.promptText'), {
					confirmButtonText: this.$t('main.confirmButtonText'),
					cancelButtonText: this.$t('main.cancelButtonText'),
					type: 'warning'
				}).then(() => {
					this.$store.dispatch('topic/deleteTopicTitle', {
						topicId: it.TopicId,
					}).then(res => {
						if (res.Success) {
							this.$message.success(res.Message);
							this.$forceUpdate();
						} else {
							this.$message.error(res.Message);
						}
					})
				}).catch(() => {

				})
			},
			handleBlur(it) {
				it.isEdit = false;
				this.$forceUpdate();
			},
			handleChangeTopicTitle(it) {
				it.isEdit = false;
				this.$store.dispatch('topic/updateTopicTitle', {
					topicId: it.TopicId,
					topicTitle: it.TopicTitle
				}).then(res => {
					if (res.Success) {
						this.$message.success(res.Message);
						this.$forceUpdate();
					} else {
						this.$message.error(res.Message);
					}
				})
			},
			handleSearchTopic() {
				this.topicIng = true;
				this.$store.dispatch('topic/getTopicList', {
					title: this.keyword
				}).then(res => {
					this.topicIng = false;
				})
			},
			handleSelectTopic(it) {
				this.$emit('onBack', it);
			},
			onAddTopic() {
				this.$store.commit('topic/SET_SELECTTOPIC_ID', null);
				this.$store.commit('topic/SET_TOPIC_DATA', {});
				this.$store.commit('topic/SET_SETTINGS', {
					"IsFreight": false,
					"IsFineTuning": false,
					"IsPineCone": false,
					"IsFreightTest": false,
					"IsFineTuningTest": false,
					"IsPineConeTest": false
				});
				this.$store.commit('topic/SET_PAGEPARAMS', {
					pageSize: 30,
					pageIndex: 1
				});
				this.$store.commit('topic/SET_TOPICLIST', []);
				
				this.$store.dispatch("topic/getDefaultSettings", {
					setting_dype: "LLM"
				}).then(res2 => {
					const settings = this.settings;
					settings.UserId = this.userInfo.UserId;
					const params = {
						"topicTitle": '新的会话',
						"isDel": false,
						"userId": this.userInfo.UserId,
						"details": [
							{
								"role": "log",
								"topicContent": 'log',
							}
						],
						"setting": settings
					}
					// this.$store.dispatch('topic/addTopic', params);
					this.scrollToBottom();
					this.$store.dispatch('topic/addTopic', params).then(res => {
						this.$emit('onBackAdd', res.data.Data);
					}).catch(e => {
					});
				})
			},
			scrollToBottom() {
				const scrollEl = this.$refs.oHistory;
				this.$nextTick(() => {
					scrollEl.scrollTo({
						top: scrollEl.scrollHeight
					})
				})
			}
		},
		mounted() {
			this.handleSearchTopic();
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/css/admin.scss';
	
	.oHistory {
		max-height: 100%;
		overflow: auto;
	}
	
	.o-his-item {
		font-weight: 400;
		color: #000462;
		line-height: 2.375rem;
		.el-icon-edit {
			opacity: 0;
		}
	
		.el-icon-delete {
			opacity: 0;
		}
	
		&:hover {
			.el-icon-edit {
				opacity: 1;
			}
	
			.el-icon-delete {
				opacity: 1;
			}
		}
	}
</style>
