module.exports = {
	login: {
		loginBoxTitle: '登录你的账号',
		noCount: '没有账号',
		wechatLogon: '微信登录',
		others: '其他',
		mobile: '手机验证码登录',
		password: '账号密码登录',
		registerAccount: '免费注册',
		obtainCode: '获取验证码',
		inputCode: '输入验证码',
		serviceAgreement: '《服务条款、隐私协议》',
		readAndAgree: '已阅读并同意以下协议',
		enterPhoneNumber: '请输入手机号',
		userName: '请输入用户名',
		userPass: '请输入密码',
		requireUser: '请输入用户名和密码',
		loginExpired: '登录已过期'
	},
	main: {
		outputPreset: '回答预设',
		setUpRole: '请选择',
		outputAccuracy: '回答精度',
		accurate: '精炼',
		creative: '扩展',
		search: '搜索历史会话',
		newTopic: '新建会话',
		enterAq: '请输入问题',
		btnCreate: '生成',
		logoutText: '退出登录',
		tokenLen: '回答长度',
		contextualContext: '前后语境',
		ignore: '忽略',
		refer: '参考',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		expDate: '账号有效期',
		recharging: '立即续费',
		activateMember: '开通会员，解锁超值权益',
		memberType: '选择最适合你的会员类型',
		oneMonth: '1个月',
		season: '3个月',
		halfAYear: '半年',
		oneYear: '1年',
		paySelect: '支付方式',
		alipay: '支付宝',
		wechatpay: '微信',
		dopay: '立即支付',
		agree: '已阅读并同意',
		payAgreemt: '服务协议',
		register: '免费注册',
		setting: '设置',
		historyTopic: '历史会话',
		promptText: '提示',
		confirmTitle: '是否确定删除?',
		intrfrom: '信息由 Logistics Ai Engine (国际物流智能引擎）提供',
		waitTopic: '等待会话完成',
		langmodule: '语言模型',
		// freightorder: '运价订单',
		fba: 'FBA查询(P)',
		fbaTest: 'FBA查询(T)',
		fbaOrderManagement: 'FBA订单管理(P)',
		fbaOrderManagementTest: 'FBA订单管理(T)',
		freightorder: '海运查询(P)',
		freightorderTest: '海运查询(T)',
		logisticsTracking: '空运追踪(P)',
		logisticsTrackingTest: '空运追踪(T)',
		isTruck: '拖卡查询(P)',
		isTruckTest: '拖卡查询(T)',
		LogisticsKnowledge: '物贸百科(P)',
		LogisticsKnowledgeTest: '物贸百科(T)',
		tipsLangModel: '可切换语言模型，如文言一心、通义千问等模型。',
		tipsFreightOrder: '选择后可查询海运运价及相关船期，和查看运链订单及信息',
		tipsTuning: '可输入快递单号、集装箱号、海运主单号、空运主单号, 查询最近的即时物流信息',
		saveOk: '保存成功',
		generating: '正在生成答案,请稍后...',
		commentplaceholder: '请输入您的评价内容',
		problemEvaluation: '问题评价',
		btnOk: '确 定',
		btnCancel: '取 消'
	}
}