module.exports = {
	login: {
		loginBoxTitle: 'Log On',
		noCount: 'No account',
		wechatLogon: 'Wechat Log On',
		others: 'Others',
		mobile: 'Mobile',
		password: 'Password',
		registerAccount: 'Register a new account',
		obtainCode: 'Obtain Code',
		inputCode: 'Input Code',
		serviceAgreement: '《Service Agreement》',
		readAndAgree: 'Read and agree',
		enterPhoneNumber: 'Please enter your phone number',
		userName: 'User Name',
		userPass: 'Password',
		requireUser: 'Please enter a username and password',
		loginExpired: 'Login has expired'
	},
	main: {
		outputPreset: 'Output Preset',
		setUpRole: 'Set up the role',
		outputAccuracy: 'Output Accuracy',
		accurate: 'Accurate',
		creative: 'Creative',
		search: 'Search',
		newTopic: 'New Topic',
		enterAq: 'Please enter a question',
		btnCreate: 'Create',
		logoutText: 'Logout',
		tokenLen: 'Token',
		contextualContext: 'Contextual Context',
		ignore: 'Ignore',
		refer: 'Refer',
		confirmButtonText: 'Confirm',
		cancelButtonText: 'Cancel',
		expDate: 'Expiration',
		recharging: 'Recharging',
		activateMember: 'Activate Member',
		memberType: 'Type',
		oneMonth: 'One Month',
		season: 'Season',
		halfAYear: 'Half A Year',
		oneYear: 'One Year',
		paySelect: 'Select',
		alipay: 'Alipay',
		wechatpay: 'Wechat',
		dopay: 'Pay',
		agree: 'Read and agree',
		payAgreemt: 'Payment Agreemt',
		register: 'Register',
		setting: 'Setting',
		historyTopic: 'History',
		promptText: 'Prompt',
		confirmTitle: 'Are you sure to delete?',
		intrfrom: 'Information provided by Logistics Ai Engine',
		waitTopic: 'Please wait for the session to complete',
		langmodule: 'Language Model',
		logisticsTracking: 'Tracking(P)',
		logisticsTrackingTest: 'Logistics Tracking(T)',
		LogisticsKnowledge: 'Logistics Knowledge(P)',
		LogisticsKnowledgeTest: 'Logistics Knowledge(T)',
		fba: 'FBA Query(P)',
		fbaTest: 'FBA Query(T)',
		fbaOrderManagement: 'FBA Order(P)',
		fbaOrderManagementTest: 'FBA Order(T)',
		freightorder: 'Rate Query(P)',
		freightorderTest: 'Rate Query(T)',
		isTruck: 'Truck Query(P)',
		isTruckTest: 'Truck Query(T)',
		tipsLangModel: 'Switchable language models, such as Classical Chinese One Heart, Tongyi Thousand Questions, and other models.',
		tipsFreightOrder: 'After selection, you can query sea freight rates and related shipping schedules, as well as view transportation chain orders and information',
		tipsTuning: 'You can enter the courier number, container number, sea freight master number, and air freight master number to query the latest real-time logistics information',
		saveOk: 'Successfully saved',
		generating: 'Generating answers, please wait...',
		commentplaceholder: 'Please enter the evaluation content.',
		problemEvaluation: 'Problem evaluation',
		btnOk: 'OK',
		btnCancel: 'Cancel'
	}
}