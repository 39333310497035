<template>
	<div class="login-container">
		<!-- Login -->
		<div class="logo"><h2><img src="../assets/img/logo-white@2x.png" style="height: 1.75rem;" /></h2></div>
		<div class="lc-box">
			<el-row :gutter="20">
			  <el-col :span="14" v-if="$store.state.system.clientWidth>=900">
					<div class="grid-content bg-purple h100 aife">
						<h2>运链打造一窗式国际<br />物流AI引擎</h2>
						<div class="lc-foo mt20">
							<i class="el-icon-circle-check"></i><span>免费试用</span>
							<i class="el-icon-circle-check"></i><span>NLP引导式互动交易</span>
							<i class="el-icon-circle-check"></i><span>打造Plugin 配合各AI模型</span>
						</div>
					</div>
				</el-col>
			  <el-col :span="$store.state.system.clientWidth>=900?10:24" class="flex-c jcc">
					<div class="grid-content bg-purple lc-form">
						<div>
							<h2>注册你的账号</h2>
							<h6>已有账号？<router-link to="/login">前去登录 <span class="arr el-icon-arrow-right"></span> </router-link></h6>
						</div>
						<div class="form-bx mt40">
							<h3>手机验证</h3>
							<div class="log-input flex aic mt10">
								<span class="el-icon-mobile-phone"></span>
								<el-input
								  placeholder="请输入手机号"
									auto-complete="off"
								  clearable>
								</el-input>
							</div>
							<div class="flex flex-jc-ac log-input mt10">
								<span class="el-icon-tickets"></span>
								<el-input
								  placeholder="输入验证码"
									auto-complete="off"
								  clearable>
								</el-input>
								<a>获取验证码</a>
							</div>
							<el-button class="btn-login w100 mt30" @click="handleSubmit">下一步</el-button>
							<div class="mt10">
								<el-checkbox /> 已阅读并同意以下协议<router-link to="/service">服务条款</router-link>、<router-link to="/pro">隐私协议</router-link>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 0
			}
		},
		methods: {
			handleSubmit() {
				// this.$router.push('/admin')
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import '../assets/css/login.scss';
</style>