<template>
	<div class="login-container">
		<!-- Login -->
		<div class="logo">
			<h2><img src="../assets/img/logo-white@2x.png" style="height: 1.75rem;" /></h2>
		</div>
		<div class="lc-box">
			<el-row :gutter="20">
				<el-col :span="12" v-if="$store.state.system.clientWidth>=900">
					<div class="grid-content bg-purple swiper-ca h100 flex jcc">
						<el-carousel height="460px" arrow="nerver">
							<el-carousel-item>
								<img src="../assets/img/swiper01.png" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="../assets/img/swiper02.png" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="../assets/img/swiper03.png" />
							</el-carousel-item>
						</el-carousel>
						<!-- <h2>运链打造一窗式国际<br />物流AI引擎</h2>
						<div class="lc-foo mt20">
							<i class="el-icon-circle-check"></i><span>免费试用</span>
							<i class="el-icon-circle-check"></i><span>NLP引导式互动交易</span>
							<i class="el-icon-circle-check"></i><span>打造Plugin 配合各AI模型</span>
						</div> -->
					</div>
				</el-col>
				<el-col :span="$store.state.system.clientWidth>=900?12:24" class="flex-c jcc">
					<div class="grid-content bg-purple lc-form" style="max-width: 26rem;">
						<div>
							<h2>{{type == 1 ? '注册你的账号': '登录你的账号'}}</h2>
							<h6>已有账号？<router-link to="/login">前去登录 <span class="arr el-icon-arrow-right"></span>
								</router-link>
							</h6>
						</div>
						<div class="wxqrcode" id="qrcode">
							<!-- <img src="../assets/img/wx-scan.png" class="w100" /> -->
							<wxlogin :appid="appid" :scope="scope" :redirect_uri="redirect_uri" :href="href" state="7891"></wxlogin>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	// import WxLogin from '../wxLogin.js';
	import wxlogin from 'vue-wxlogin';
	
	export default {
		data() {
			return {
				active: 0,
				code: '',
				appid: 'wx7793232e8054b71a',
				scope: "snsapi_login",
				showqrcode: false,
				href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCglkaXNwbGF5OiBub25lOw0KfQ0KaWZyYW1lIHsNCgl3aWR0aDogMjAwcHg7DQoJaGVpZ2h0OiAyMDBweDsNCn0NCiN3eF9kZWZhdWx0X3RpcCB7DQoJaGVpZ2h0OiAxcHg7DQoJZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5xcmNvZGUgew0KCXdpZHRoOiAyMDBweDsNCgloZWlnaHQ6IDIwMHB4Ow0KCWJvcmRlcjogMHB4IWltcG9ydGFudDsNCgltYXJnaW4tdG9wOiAxNXB4Ow0KCW1hcmdpbi1sZWZ0OiAtMjhweDsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMgew0KCXRleHQtYWxpZ246IGNlbnRlcjsNCglwYWRkaW5nOiAwcHg7DQoJbWFyZ2luLXRvcDogLThweDsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMgcDpsYXN0LWNoaWxkIHsNCglkaXNwbGF5OiBub25lOw0KfQ0K",
				redirect_uri: encodeURI("http://chat.ezcarry.com/wxloading"),
				type: null
			}
		},
		components: {
			 wxlogin
		},
		mounted() {
			const { type } = this.$route.query;
			this.type = type;
		}
	}
</script>
<style>
	.el-carousel__item {
		text-align: right;
	}
	.el-carousel__indicators {
		position: fixed!important;
	}
	.el-carousel__button {
		width: 6px!important;
		height: 6px!important;
		border-radius: 50%;
	}
	.el-carousel__arrow {
		opacity: 0;
	}
</style>
<style lang="scss" scoped>
	@import '../assets/css/login.scss';
	.wxqrcode {
		width: 273px;
		height: 275px;
		overflow: hidden;
		border-radius: 6px;
		border: 1px solid #eee;
		padding-top: 10px;
		margin-top: 30px;
	}
	
	.logo {
		font-size: 1.5rem;
		img {
			float: left;
			margin-right: 6px;
		}
	}
	.swiper-ca {
		img {
			height: 100%;
		}
	}
</style>
